import SourceCmsPage from 'SourceRoute/CmsPage/CmsPage.component';
import CmsBlock from 'Component/CmsBlock';
import Html from 'Component/Html';

export class CmsPage extends SourceCmsPage {
    renderContent() {
        const {
            isLoading,
            page: { content }
        } = this.props;

        if (!isLoading && !content) {
            return null;
        }

        if (!content) {
            return (
                <span>{ __("Loading...") }</span>
            );
        }

        return <Html content={ content } />;
    }

    renderOneColumn() {
        const { isBreadcrumbsActive, page } = this.props;
        const ogImageUrl = page?.og_image_url;

        if (ogImageUrl && ogImageUrl.trim() !== '') {
            // Return with the meta tag
            return (
                <main
                    block="CmsPage"
                    mods={{ isBreadcrumbsHidden: !isBreadcrumbsActive }}
                >
                    <div block="CmsPage" elem="Wrapper">
                        <meta property="og:image" content={ogImageUrl}/>
                        <meta name="og:image" content={ogImageUrl}/>
                        <div block="CmsPage" elem="Content">
                            {this.renderContent()}
                        </div>
                    </div>
                </main>
            );
        } else {
            // Return without the meta tag
            return (
                <main
                    block="CmsPage"
                    mods={{ isBreadcrumbsHidden: !isBreadcrumbsActive }}
                >
                    <div block="CmsPage" elem="Wrapper">
                        <div block="CmsPage" elem="Content">
                            {this.renderContent()}
                        </div>
                    </div>
                </main>
            );
        }
    }


    renderTwoColumnsLeft() {
        const {isBreadcrumbsActive, page} = this.props;
        const ogImageUrl = page?.og_image_url;

        if (ogImageUrl && ogImageUrl.trim() !== '') {
            return (
                <main
                    block="CmsPage"
                    mods={{isBreadcrumbsHidden: !isBreadcrumbsActive, twoColumnsLeft: true}}
                >
                    <div block="CmsPage" elem="Wrapper">
                        <meta property="og:image" content={ogImageUrl}/>
                        <meta name="og:image" content={ogImageUrl}/>
                        <div block="CmsPageCol">
                            <CmsBlock identifier="sidebar-links-1" collapsible mix="CmsSidebar"/>
                            <CmsBlock identifier="sidebar-links-2" collapsible mix="CmsSidebar"/>
                            <CmsBlock identifier="sidebar-links-3" collapsible mix="CmsSidebar"/>
                            <CmsBlock identifier="sidebar-links-4" collapsible mix="CmsSidebar"/>
                            <CmsBlock identifier="sidebar-links-5" collapsible mix="CmsSidebar"/>
                        </div>
                        <div block="CmsPageCol">
                            {this.renderHeading()}
                            <div block="CmsPage" elem="Content">
                                {this.renderContent()}
                            </div>
                        </div>
                    </div>
                </main>
            );
        } else {
            return (
                <main
                    block="CmsPage"
                    mods={{isBreadcrumbsHidden: !isBreadcrumbsActive, twoColumnsLeft: true}}
                >
                    <div block="CmsPage" elem="Wrapper">
                        <div block="CmsPageCol">
                            <CmsBlock identifier="sidebar-links-1" collapsible mix="CmsSidebar"/>
                            <CmsBlock identifier="sidebar-links-2" collapsible mix="CmsSidebar"/>
                            <CmsBlock identifier="sidebar-links-3" collapsible mix="CmsSidebar"/>
                            <CmsBlock identifier="sidebar-links-4" collapsible mix="CmsSidebar"/>
                            <CmsBlock identifier="sidebar-links-5" collapsible mix="CmsSidebar"/>
                        </div>
                        <div block="CmsPageCol">
                            {this.renderHeading()}
                            <div block="CmsPage" elem="Content">
                                {this.renderContent()}
                            </div>
                        </div>
                    </div>
                </main>
            );
        }
    }

    renderPageLayout() {
        const { page: { page_width } } = this.props; // page_width = ['default', 'full']

        switch (page_width) {
        case 'default':
            return this.renderTwoColumnsLeft();
        default:
            return this.renderOneColumn();
        }
    }

    render() {
        return this.renderPageLayout();
    }
}

export default CmsPage;
