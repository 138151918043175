import { ConfigQuery as SourceConfigQuery } from 'SourceQuery/Config.query';
import { Field } from 'Util/Query';

export class ConfigQuery extends SourceConfigQuery {
    getCustomerAttributes() {
        return new Field('customerAdditionalAttributes')
            .addFieldList(this.getCustomerAttributesFields());
    }

    getCustomerAttributesFields() {
        return [
            'tip_par',
            'tip_ten',
            'sex'
        ];
    }

    getFreeShipping() {
        return new Field('freeShipping')
            .addFieldList(this._getFreeShippingFields());
    }

    _getFreeShippingFields() {
        return [
            'threshold',
            'customer_message',
            'guest_message',
            'logged_in_user_message',
            'customer_dashboard_message'
        ];
    }

    getGTMConfiguration() {
        return new Field('getGtm')
            .setAlias('gtm')
            .addFieldList(this._getGTMConfigurationFields());
    }

    _getGTMConfigurationFields() {
        return [
            'enabled',
            'gtm_id'
        ];
    }

    getCity(regionLabel) {
        return new Field('getCitySelectValues')
            .addArgument('county', 'String', regionLabel)
            .addFieldList([this._getCityItems()])
    }
    _getCityItems() {
        return new Field('items').addFieldList(['name', 'zip_code']);
    }

    getZipCode(county, city) {
        return new Field('getZipcode')
            .addArgument('county', 'String', county)
            .addArgument('city', 'String', city)
            .addFieldList(['zipcode'])
    }

    getPhonePrefix(country_id) {
        return new Field('getPhonePrefix')
            .addArgument('country_id', 'String', country_id)
            .addField('prefix')
    }

    getRedirectData() {
        return new Field('getRedirectData')
            .addFieldList(['default', 'url'])
    }

    getPromoData() {
        return new Field('promoData')
            .addFieldList(['LIO_categories', 'LIO_products']);
    }

    getCartRuleSpecialPriceData() {
        return new Field('cartRuleSpecialPrice')
            .addFieldList(this.getCartRuleSpecialPriceDataFields());
    }

    getStickerNotificationsData() {
        return new Field('sticker_notifications')
            .addFieldList(['enabled','selected_sticker','message','quantity_display_limit', 'display_on_odd_number']);
    }

    getProductCardStickerData() {
        return new Field('product_card_sticker')
            .addFieldList(['enabled','image','sku_list']);
    }

    getNewsletterConfig() {
        return new Field('newsletter').addFieldList(['discount_disabled', 'enabled', 'banner_image', 'block_id', 'title', 'header_text']);
    }

    getCartRuleSpecialPriceDataFields() {
        return [
            'use_cart_rule_special_price_styling',
            'sticker_text',
            'sticker_background_color',
            'sticker_text_color',
            'start_date',
            'end_date',
            'excluded_skus']
    }

    getFeaturesConfig() {
        return new Field('getFeatures').setAlias('featureList').addFieldList(['name','enabled']);
    }

    getEasyBoxAllowCodConfig() {
        return new Field('getEasyBoxAllowCod');
    }

    getPopupInformationConfig() {
        return new Field('getPopupInformation').addFieldList(this.getPopupFields());
    }

    getExcludedProducts() {
        return new Field('getExcludedProducts').addFieldList(['country_code','sku_list']);
    }

    getPointsExcludedProducts() {
        return new Field('getPointsExcludedProducts');
    }

    getCmsBlockNotificationId() {
        return new Field('getCmsBlockNotificationId');
    }

    getCmsLIO() {
        return new Field('cms_LIO');
    }

    getLoyaltyConfigData() {
        return new Field('fidelizare_config')
            .addFieldList(['enable_menu','test_mode','free_shipping_threshold_bronze','free_shipping_threshold_silver','free_shipping_threshold_gold']);
    }

    getClickAndCollectFields() {
        return [
            'name',
            'working_time',
            'address',
            'store_id',
            'store_code',
            'latitude',
            'longitude',
            'is_active'
        ]
    }

    getPopupFields() {
        return [
            'enabled',
            'block_id',
            'popup_timer',
            'popup_lifetime'
        ]
    }

    getLotteryConfig() {
        return new Field('lottery_config')
            .addFieldList(['is_enabled','lottery_name','end_date','promo_text', 'promo_link', 'guest_promo_text', 'guest_popup_text', 'minimum_order_value', 'checkout_switch_shipping_calculator', 'modal_cart_title', 'modal_cart_ok_text', 'modal_cart_cancel_text']);
    }

    getOgImageUrl() {
        return new Field('getOgImageUrl');
    }

    getPromoItemsForPopup() {
        return new Field('promoItemsForPopup')
            .addFieldList([
                'enable',
                'title',
            ]);
    }

    getPayuV() {
        return new Field('payuv');
    }
}

export default new ConfigQuery();
